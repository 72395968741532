import React, { useEffect, useState } from 'react';

/**
  Supported message through postMessage.

  1. "get-cookies" -> will send a postMessage to top window in the format
                      {cookies: "cookie1=somevalue1; cookie2=somevalue2"}
  2. {
      name: "cookieName",
      exp_attr: "max-age" or "expires" (default: "max-age"),
      exp_sec: 60 (default: 0), // number of seconds for cookie expiration, 0 means session cookie,
      api: "document.cookie" | "CookieStore" (default: "document.cookie"), which API to use for cookie setting
    }

  After each message of type 2, iframe will send a message back to the top window
  in format specified at point 1.
 */

const IframePage = () => {

  const setCookieUsingDocument = (cookieName, value, expAttr, expInSeconds) => {
    if (!expInSeconds || expInSeconds <= 0) { // session cookie
      return document.cookie = `${cookieName}=${value}; Path=/; SameSite=None; Secure;`;
    }

    let expiration = `Max-Age=${expInSeconds};`; // Max-Age
    if (expAttr.toLowerCase() == 'expires') {
      const d = new Date();
      d.setTime(d.getTime() + 1000 * expInSeconds);
      expiration = `Expires=${d.toUTCString()};`; // Expires
    }
    document.cookie = `${cookieName}=${value}; Path=/; ${expiration} SameSite=None; Secure;`;
  };

  const setCookieUsingCookieStore = async (cookieName, value, expInSeconds) => {
    const options = {
      name: cookieName,
      value: value,
      sameSite: 'none',
    };

    if (expInSeconds > 0) {
      options.expires = Date.now() + 1000 * expInSeconds;
    }

    await window.cookieStore.set(options);
  };

  const handlePostMessage = (message) => {
    // "get-cookies" message will send all the cookies
    if (message.data === 'get-cookies') {
      window.top.postMessage({
        cookies: document.cookie,
      }, '*');
      return;
    }

    // any object message will be treated as an attempt to set cookie
    const cookieName = message.data.name;
    const expAttr = message.data.exp_attr || 'max-age';
    const expInSeconds = message.data.exp_sec || 0;
    const api = message.data.api || 'document.cookie';
    const value = message.data.value || 'test';

    if (!cookieName) {
      console.error('Cookie name was not specified!');
      return;
    }

    if (api === 'document.cookie') {
      setCookieUsingDocument(cookieName, value, expAttr, expInSeconds);
      console.log(`Cookie with name ${cookieName} was set using ${api}`);
    }

    if (api === 'CookieStore') {
      setCookieUsingCookieStore(cookieName, value, expInSeconds).then(() => {
        console.log(`Cookie with name ${cookieName} was set using ${api}`);
      }, (reason) => {
        console.error(`Cookie with name ${cookieName} was set using ${api}`, reason);
      });
    }
  };

  useEffect(() => {
    window.addEventListener('message', handlePostMessage);
    // sending a message to confirm that iframe is ready to receive commands
    window.top.postMessage('ready', '*');
  }, [])

  return (
    <div>This is an iframe used by other tests</div>
  );
}

export default IframePage;
